import * as Yup from 'yup';

const schemaRegister = Yup.object().shape({
    email: Yup.string().required('Campo obrigatório').email('Informe um email válido'),
    password: Yup.string().required('Campo obrigatório').min(6, 'Mínimo 6 letras'),
    firstName: Yup.string().required('Campo obrigatório'),
    lastName: Yup.string().required('Campo obrigatório'),
    phone: Yup.string().required('Campo obrigatório'),
    confirmPassword: Yup.string()
        .test('passwords-match', 'Senhas não conferem', function (value) {
            return this.parent.password === value
        })
});

export default schemaRegister;