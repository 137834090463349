import { IMagazineResponse } from "../../data/interfaces/magazine/IMagazine";
import { IMagazineState } from "./interfaces/IMagazineState";
import { MagazineActionKeys, MagazineActionUnion } from "./Magazine.actions";

const initialState: IMagazineState = {
  currentMagazine: {} as IMagazineResponse,
  magazines: [],
  isLoading: false,
  error: undefined,
  message: undefined,
}

const magazineReducer = (state = initialState, action: MagazineActionUnion): IMagazineState => {

  switch (action.type) {
    case MagazineActionKeys.LIST_MAGAZINES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.LIST_MAGAZINES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        magazines: action.payload,
      };

    case MagazineActionKeys.GET_MAGAZINE_REQUEST:
    case MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.GET_MAGAZINE_SUCCESS:
    case MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentMagazine: action.payload,
      };

    case MagazineActionKeys.CREATE_MAGAZINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.CREATE_MAGAZINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        magazines: [action.payload, ...state.magazines],
      };

    case MagazineActionKeys.UPDATE_MAGAZINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.UPDATE_MAGAZINE_SUCCESS:
      {
        let magazines = [...state.magazines];
        var oldUserIndex = magazines.findIndex(x => x.magazineId == action.payload.magazineId);
        magazines[oldUserIndex] = action.payload;

        return {
          ...state,
          isLoading: false,
          magazines: magazines,
          currentMagazine: action.payload
        };
      }

    case MagazineActionKeys.SEND_PAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.SEND_PAGES_SUCCESS:
      {
        let currentMagazine = { ...state.currentMagazine };
        currentMagazine.pages = [...currentMagazine.pages, ...action.payload];

        return {
          ...state,
          isLoading: false,
          currentMagazine: currentMagazine
        };
      }

    case MagazineActionKeys.DELETE_MAGAZINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.DELETE_MAGAZINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        magazines: [...state.magazines].filter(x => x.magazineId != action.payload)
      };

    case MagazineActionKeys.DELETE_MAGAZINE_PAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MagazineActionKeys.DELETE_MAGAZINE_PAGE_SUCCESS:
      {
        let currentMagazine = { ...state.currentMagazine };
        currentMagazine.pages = currentMagazine.pages.filter(x => x.magazinePageId != action.payload);

        return {
          ...state,
          isLoading: false,
          currentMagazine: currentMagazine
        };
      }

    case MagazineActionKeys.CLEAR_CURRENT_MAGAZINE:
      return {
        ...state,
        isLoading: false,
        currentMagazine: {} as IMagazineResponse
      };

    case MagazineActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineReducer;
