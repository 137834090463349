import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IMagazineResponse, ISendPages, IUpdateMagazine } from "./interfaces/magazine/IMagazine";

export class MagazineApi {
    static async listMagazines(): Promise<AxiosResponse<IMagazineResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/magazine/list?onlyActives=false');
    }

    static async getMagazine(request: string | null): Promise<AxiosResponse<IMagazineResponse>> {
        const axios = await Http.axios();
        return axios.get(`/magazine/get/${request}`);
    }

    static async getFirstPageMagazine(): Promise<AxiosResponse<IMagazineResponse>> {
        const axios = await Http.axios();
        return axios.get(`/magazine/getFirstPage`);
    }

    static async createMagazine(request: string): Promise<AxiosResponse<IMagazineResponse>> {
        const axios = await Http.axios();
        return axios.post(`/magazine/create?title=${request}`);
    }

    static async updateMagazine(request: IUpdateMagazine): Promise<AxiosResponse<IMagazineResponse>> {
        const axios = await Http.axios();
        return axios.put(`/magazine/update/${request.magazineId}`, request);
    }

    static async sendPages(request: ISendPages): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();

        for (var x = 0; x < request.files.length; x++) {
            formData.append('files', request.files[x]);
        }

        return axios.post(`/magazine/sendPages/${request.magazineId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async deleteMagazine(request: string) {
        const axios = await Http.axios();
        return axios.delete(`/magazine/delete/${request}`);
    }

    static async deleteMagazinePage(request: number) {
        const axios = await Http.axios();
        return axios.delete(`/magazine/deletePage/${request}`);
    }
}