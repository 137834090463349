import React, { FC, useEffect, useState } from "react";
import { Button, Divider, Skeleton, Table } from "antd";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { ConnectedProps, connect, useDispatch } from "react-redux";
import PageContent from "../../../components/ui/pageContent";
import styles from './Magazine.module.scss';
import { MagazineActions } from "../../../store/magazine/Magazine.actions";
import Form from "../../../components/ui/form";
import schema from "./Magazine.schema";
import FieldForm from "../../../components/ui/fieldForm";
import TextArea from "../../../components/ui/textArea";
import SelectForm from "../../../components/ui/selectForm";
import { simNaoOptions } from "../../../utils/optionsHelper";
import { history } from "../../../store";
import { IMagazineState } from "../../../store/magazine/interfaces/IMagazineState";
import { IMagazinePageResponse, ISendPages, IUpdateMagazine } from "../../../data/interfaces/magazine/IMagazine";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import Confirm from "../../../components/ui/confirm";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'

export interface IAdmMagazine {
    match: any;
}

const AdmMagazine: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [files, setFiles] = useState<FileList>();
    const [items, setItems] = React.useState([] as IMagazinePageResponse[]);

    useEffect(() => {
        dispatch(MagazineActions.getMagazine(props.match.params.id));
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.currentMagazine?.pages) {
            setItems(props.currentMagazine.pages);
        }
    }, [props.currentMagazine.pages]);

    const submit = (values: IUpdateMagazine) => {
        if (values) {
            values.pagesId = items.map((page) => page.magazinePageId);
            dispatch(MagazineActions.updateMagazine(values));
        }

        setIsSubmit(false);
    }

    const sendFiles = () => {
        if (files) {
            let request = {
                magazineId: props.currentMagazine.magazineId,
                files: files
            } as ISendPages;

            dispatch(MagazineActions.sendPages(request));
            setFiles(undefined);
        }
    }

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFiles(e);
        }
    }

    const removeFile = () => {
        setFiles(undefined);
    }

    const addDefaultSrc = (ev: any, pagePath: string) => {
        ev.target.src = `${process.env.REACT_APP_BASE_URL_PAGES}${pagePath}`
    }

    const handleRemovePage = (magazinePageId: number) => {
        dispatch(MagazineActions.deletePageMagazine(magazinePageId));
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    }

    return (
        <PageContent title='Revista' className={styles['container']}>
            {props.isLoading ?
                <Skeleton active />
                :
                <>
                    <Form onSubmit={submit} schema={schema} initialValues={props.currentMagazine} isSubmited={isSubmit}>
                        <div>
                            <Button type="primary" onClick={() => setIsSubmit(true)}>Salvar</Button>
                            <Button className={styles['button']} type='link' onClick={() => history.goBack()}>Cancelar</Button>
                        </div>
                        <FieldForm type='hidden' name='magazineId'></FieldForm>
                        <FieldForm key='title' label='Título' name='title' className={styles['inputForm']} tagRequired={true} ></FieldForm>
                        <SelectForm name={'isActive'} label='Ativo' options={simNaoOptions} tagRequired={true} placeholder={'Selecione...'} />
                        <TextArea rows={3} key='description' label='Descrição' name='description'></TextArea>
                    </Form>
                    <Divider />
                    <div>
                        <h3>Páginas da Revista</h3>
                        <div>
                            <div className={styles['upload']}>
                                {
                                    files ?
                                        <div className={styles['container']}>
                                            <div className={styles['fileSelected']}>
                                                <div className={styles['fileName']}>{files.length} Arquivos Selecionados</div>
                                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                                            </div>
                                            <Button type="primary" onClick={sendFiles}>Enviar Arquivos</Button>
                                        </div>
                                        :
                                        <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportar')?.click()}>Selecionar Arquivo</div>
                                }
                                <input multiple accept="image/jpeg, image/jpg" type='file' id='fileImportar' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                            </div>
                        </div>
                        <SortableList onSortEnd={onSortEnd} className={styles['magazine-pages']} draggedItemClassName="dragged">
                            {
                                items?.map((page) => (
                                    <SortableItem key={page.magazinePageId}>
                                        <div key={page.magazinePageId} style={{ height: 260, width: 180 }}>
                                            <img
                                                draggable={false}
                                                style={{ maxHeight: 200 }}
                                                src={`${process.env.REACT_APP_BASE_URL_PAGES_THUMB}${page.pagePath}`}
                                                onError={(e) => addDefaultSrc(e, page.pagePath)}
                                                key={page.magazinePageId}
                                                alt={`Page ${page.magazinePageId}`}
                                            />
                                            <Button size="small" className={styles['button-delete']} onClick={() => Confirm('Excluir Página', 'Deseja realmente excluir essa página?', () => handleRemovePage(page.magazinePageId))} >
                                                Remover
                                            </Button>
                                        </div>
                                    </SortableItem>
                                ))
                            }
                        </SortableList>
                    </div>
                </>
            }
        </PageContent>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.magazine,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAdmMagazine;

export default connector(AdmMagazine);

