import React, { FC, CSSProperties } from 'react';
import styles from './Input.module.scss';

export interface IInput {
  label?: string | React.ReactNode;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: CSSProperties;
  className?: string;
  type?: string;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  readonly?: boolean;
  onInput?: (value: string) => void;
  onChange?: () => void;
  value?: string;
  defaultValue?: string;
  autoComplete?: 'true' | 'false';
  tagRequired?: boolean,
  hidden?: boolean
}

const Field: FC<IInput> = ({
  label, style, styleInput, className, defaultValue,
  type = 'text', placeholder, autoComplete,
  onFocus, onBlur, onInput, disabled, value, readonly,
  onChange, tagRequired, hidden
}) => {

  if (!placeholder && typeof (label) == 'string') {
    placeholder = label;
  }

  if (tagRequired && typeof (label) == 'string') {
    label = <>{label} <span style={{ color: 'red' }}>*</span></>;
  }

  return (
    <div hidden={hidden} className={`${styles['contentInput']} ${className ?? ''}`} style={style}>
      <label className={styles['description']}>{label}</label>
      <label
        hidden={type === 'hidden'}
        className={styles['labelInput']}>
        <input
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readonly}
          style={styleInput}
          onInput={(e: any) => onInput && onInput(e.target.value)}
          onChangeCapture={onChange}
          onBlur={(e: any) => onBlur && onBlur(e.target.value)}
          autoComplete={autoComplete ? autoComplete : 'true'}
          defaultValue={defaultValue}
          value={value}
        />
      </label>
    </div>)

};

export default Field;
