import React from "react";
import { ColumnProps } from "antd/lib/table";
import { IUserResponse } from "../../../data/interfaces/user/IUserResponse";
import { getFilterSearchProps, sorter } from "../../../utils/helpers";
import locations from '../../../utils/estados-cidades.json';

export const getColumns = (users: IUserResponse[]) => {
    return [
        {
            title: 'Nome',
            dataIndex: 'fullName',
            sorter: (a, b) => sorter(a.fullName, b.fullName),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            ...getFilterSearchProps('fullName', 'Nome'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.email, b.email),
            showSorterTooltip: false,
            ...getFilterSearchProps('email', 'Email'),
        },
        {
            title: 'Telefone',
            dataIndex: 'phone',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.phone, b.phone),
            showSorterTooltip: false,
            ...getFilterSearchProps('phone', 'Telefone'),
        },
        {
            title: 'Cidade/UF',
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {

                if (record.stateId) {

                    var stateName = locations.states.find(x => x?.id == record.stateId)?.name;
                    var cityName = locations.cities.find(x => x?.id == record.cityId)?.name;

                    return cityName + '/' + stateName;
                }
            }
        }
    ] as Array<ColumnProps<IUserResponse>>;
}