import React from "react";

const TermosUso = () => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: '20px',
    };

    const headingStyle = {
        color: '#333',
        borderBottom: '2px solid #333',
        paddingBottom: '5px',
    };

    const sectionStyle = {
        marginBottom: '15px',
    };

    const listItemStyle = {
        listStyle: 'none',
        marginBottom: '5px',
    };

    const strongStyle = {
        fontWeight: 'bold',
    };

    const paragraphStyle = {
        marginBottom: '15px',
    };

    return (
        <div style={containerStyle}>
            {/*Termos de uso */}
            <h2 style={headingStyle}>Termos de Uso</h2>
            <p style={paragraphStyle}>
                Bem-vindo a Revista Moda Atual
                <br />
                Antes de continuar, pedimos que leia atentamente os Termos de Uso a seguir. Ao utilizar nosso serviço, você concorda com as condições descritas abaixo.
            </p>

            <div style={sectionStyle}>
                <h3 style={headingStyle}>Cadastro e Acesso:</h3>
                <ul>
                    <li style={listItemStyle}>Para desfrutar de todas as funcionalidades do nosso sistema e visualizar todas as páginas das revistas publicadas, é necessário se cadastrar.</li>
                    <li style={listItemStyle}>O usuário é responsável por manter a confidencialidade de suas credenciais de acesso.</li>
                </ul>
            </div>

            <div style={sectionStyle}>
                <h3 style={headingStyle}>Uso Adequado:</h3>
                <ul>
                    <li style={listItemStyle}>Ao utilizar nosso sistema, compromete-se a respeitar todas as leis aplicáveis e a não violar os direitos de terceiros.</li>
                    <li style={listItemStyle}>Não é permitido o uso do serviço para atividades ilegais, prejudiciais ou que possam danificar a reputação do sistema.</li>
                </ul>
            </div>

            <div style={sectionStyle}>
                <h3 style={headingStyle}>Privacidade:</h3>
                <ul>
                    <li style={listItemStyle}>Os dados fornecidos durante o cadastro são utilizados exclusivamente para o controle de usuários e não são compartilhados, vendidos ou divulgados a terceiros sem o seu consentimento.</li>
                </ul>
            </div>

            <div style={sectionStyle}>
                <h3 style={headingStyle}>Conteúdo das Revistas:</h3>
                <ul>
                    <li style={listItemStyle}>As revistas publicadas em nosso sistema abordam informações sobre lojas de moda e roupas.</li>
                    <li style={listItemStyle}>Todo o conteúdo é de propriedade intelectual protegida e não pode ser reproduzido ou distribuído sem autorização.</li>
                </ul>
            </div>

            <div style={sectionStyle}>
                <h3 style={headingStyle}>Alterações nos Termos:</h3>
                <ul>
                    <li style={listItemStyle}>Podemos atualizar estes Termos de Uso periodicamente. Recomendamos que reveja regularmente para estar ciente de quaisquer alterações.</li>
                </ul>
            </div>

            <p style={paragraphStyle}>Última atualização: 27/12/2023</p>
            <p style={paragraphStyle}>
                Estes Termos de Uso e Política de Privacidade representam um acordo legal entre você e nosso sistema de revista online. Ao continuar a utilizar nossos serviços, você concorda com todas as condições aqui estabelecidas.
            </p>
        </div>
    );
}


export default TermosUso;