import { IMagazinePageResponse, IMagazineResponse, ISendPages, IUpdateMagazine } from "../../data/interfaces/magazine/IMagazine";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum MagazineActionKeys {
  LIST_MAGAZINES_REQUEST = 'LIST_MAGAZINES_REQUEST',
  LIST_MAGAZINES_SUCCESS = 'LIST_MAGAZINES_SUCCESS',

  GET_MAGAZINE_REQUEST = 'GET_MAGAZINE_REQUEST',
  GET_MAGAZINE_SUCCESS = 'GET_MAGAZINE_SUCCESS',

  GET_FRIST_PAGE_MAGAZINE_REQUEST = 'GET_FRIST_PAGE_MAGAZINE_REQUEST',
  GET_FRIST_PAGE_MAGAZINE_SUCCESS = 'GET_FRIST_PAGE_MAGAZINE_SUCCESS',

  CREATE_MAGAZINE_REQUEST = 'CREATE_MAGAZINE_REQUEST',
  CREATE_MAGAZINE_SUCCESS = 'CREATE_MAGAZINE_SUCCESS',

  UPDATE_MAGAZINE_REQUEST = 'UPDATE_MAGAZINE_REQUEST',
  UPDATE_MAGAZINE_SUCCESS = 'UPDATE_MAGAZINE_SUCCESS',

  SEND_PAGES_REQUEST = 'SEND_PAGES_REQUEST',
  SEND_PAGES_SUCCESS = 'SEND_PAGES_SUCCESS',

  DELETE_MAGAZINE_REQUEST = 'DELETE_MAGAZINE_REQUEST',
  DELETE_MAGAZINE_SUCCESS = 'DELETE_MAGAZINE_SUCCESS',

  DELETE_MAGAZINE_PAGE_REQUEST = 'DELETE_MAGAZINE_PAGE_REQUEST',
  DELETE_MAGAZINE_PAGE_SUCCESS = 'DELETE_MAGAZINE_PAGE_SUCCESS',

  CLEAR_CURRENT_MAGAZINE = 'CLEAR_CURRENT_MAGAZINE',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const MagazineActions = {
  listMagazines: (): ListMagazinesAction =>
    createAction(MagazineActionKeys.LIST_MAGAZINES_REQUEST),
  listMagazinesSuccess: (response: IMagazineResponse[]): ListMagazinesSuccessAction =>
    createAction(MagazineActionKeys.LIST_MAGAZINES_SUCCESS, response),

  getMagazine: (request: string): GetMagazineAction =>
    createAction(MagazineActionKeys.GET_MAGAZINE_REQUEST, request),
  getMagazineSuccess: (response: IMagazineResponse): GetMagazineSuccessAction =>
    createAction(MagazineActionKeys.GET_MAGAZINE_SUCCESS, response),

  getFirstPageMagazine: (): GetFirstPageMagazineAction =>
    createAction(MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_REQUEST),
  getFirstPageMagazineSuccess: (response: IMagazineResponse): GetFirstPageMagazineSuccessAction =>
    createAction(MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_SUCCESS, response),

  createMagazine: (request: string): CreateMagazineAction =>
    createAction(MagazineActionKeys.CREATE_MAGAZINE_REQUEST, request),
  createMagazineSuccess: (response: IMagazineResponse): CreateMagazineSuccessAction =>
    createAction(MagazineActionKeys.CREATE_MAGAZINE_SUCCESS, response),

  updateMagazine: (request: IUpdateMagazine): UpdateMagazineAction =>
    createAction(MagazineActionKeys.UPDATE_MAGAZINE_REQUEST, request),
  updateMagazineSuccess: (response: IMagazineResponse): UpdateMagazineSuccessAction =>
    createAction(MagazineActionKeys.UPDATE_MAGAZINE_SUCCESS, response),

  sendPages: (request: ISendPages): SendPagesAction =>
    createAction(MagazineActionKeys.SEND_PAGES_REQUEST, request),
  sendPagesSuccess: (response: IMagazinePageResponse[]): SendPagesSuccessAction =>
    createAction(MagazineActionKeys.SEND_PAGES_SUCCESS, response),

  deleteMagazine: (request: string): DeleteMagazineAction =>
    createAction(MagazineActionKeys.DELETE_MAGAZINE_REQUEST, request),
  deleteMagazineSuccess: (response: string): DeleteMagazineSuccessAction =>
    createAction(MagazineActionKeys.DELETE_MAGAZINE_SUCCESS, response),

  deletePageMagazine: (request: number): DeletePageMagazineAction =>
    createAction(MagazineActionKeys.DELETE_MAGAZINE_PAGE_REQUEST, request),
  deletePageMagazineSuccess: (response: number): DeletePageMagazineSuccessAction =>
    createAction(MagazineActionKeys.DELETE_MAGAZINE_PAGE_SUCCESS, response),

  clearCurrentMagazine: (): ClearCurrentMagazineAction => createAction(MagazineActionKeys.CLEAR_CURRENT_MAGAZINE),

  defaultFailure: (err: string): DefaultFailureAction => createAction(MagazineActionKeys.DEFAULT_FAILED, err),
};

export type MagazineActionUnion = ActionsUnion<typeof MagazineActions>;

export type ListMagazinesAction = Action<MagazineActionKeys.LIST_MAGAZINES_REQUEST>;
export type ListMagazinesSuccessAction = Action<MagazineActionKeys.LIST_MAGAZINES_SUCCESS, IMagazineResponse[]>;

export type GetMagazineAction = Action<MagazineActionKeys.GET_MAGAZINE_REQUEST, string | null>;
export type GetMagazineSuccessAction = Action<MagazineActionKeys.GET_MAGAZINE_SUCCESS, IMagazineResponse>;

export type GetFirstPageMagazineAction = Action<MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_REQUEST>;
export type GetFirstPageMagazineSuccessAction = Action<MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_SUCCESS, IMagazineResponse>;

export type CreateMagazineAction = Action<MagazineActionKeys.CREATE_MAGAZINE_REQUEST, string>;
export type CreateMagazineSuccessAction = Action<MagazineActionKeys.CREATE_MAGAZINE_SUCCESS, IMagazineResponse>;

export type UpdateMagazineAction = Action<MagazineActionKeys.UPDATE_MAGAZINE_REQUEST, IUpdateMagazine>;
export type UpdateMagazineSuccessAction = Action<MagazineActionKeys.UPDATE_MAGAZINE_SUCCESS, IMagazineResponse>;

export type SendPagesAction = Action<MagazineActionKeys.SEND_PAGES_REQUEST, ISendPages>;
export type SendPagesSuccessAction = Action<MagazineActionKeys.SEND_PAGES_SUCCESS, IMagazinePageResponse[]>;

export type DeleteMagazineAction = Action<MagazineActionKeys.DELETE_MAGAZINE_REQUEST, string>;
export type DeleteMagazineSuccessAction = Action<MagazineActionKeys.DELETE_MAGAZINE_SUCCESS, string>;

export type DeletePageMagazineAction = Action<MagazineActionKeys.DELETE_MAGAZINE_PAGE_REQUEST, number>;
export type DeletePageMagazineSuccessAction = Action<MagazineActionKeys.DELETE_MAGAZINE_PAGE_SUCCESS, number>;

export type ClearCurrentMagazineAction = Action<MagazineActionKeys.CLEAR_CURRENT_MAGAZINE>;

export type DefaultFailureAction = Action<MagazineActionKeys.DEFAULT_FAILED, string>;
