import { Button } from "antd";
import React, { FC } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import Form from "../../components/ui/form";
import FieldForm from "../../components/ui/fieldForm";
import schemaResetPassword from "./ResetPassword.schema";
import { IResetPassword } from "../../data/interfaces/auth/IResetPassword";
import { AuthActions } from "../../store/auth/Auth.actions";

const RecoverPassword: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const submitLogin = (data: IResetPassword) => {

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (data && code) {
            data.code = code;
            dispatch(AuthActions.resetPassword(data));
        }
    };

    return (
        <>
            <div style={{ width: '100%', paddingLeft: '50%', marginLeft: -105 }}>
                <PageContent title='Recuperar Senha'>
                    <Form onSubmit={submitLogin} schema={schemaResetPassword} resetForm={false}>
                        <FieldForm label='Email' name='email' className='inputLogin'></FieldForm>
                        <FieldForm label='Senha' name='password' type='password' className='inputLogin'></FieldForm>
                        <Button loading={props.isLoading} htmlType='submit'>Alterar Senha</Button>
                    </Form>
                </PageContent>
            </div>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.auth,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(RecoverPassword);