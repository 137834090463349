import React from "react";
import styles from "./Magazines.module.scss";
import { ColumnProps } from "antd/lib/table";
import { getFilterSearchProps, sorter } from "../../../utils/helpers";
import { IMagazineResponse } from "../../../data/interfaces/magazine/IMagazine";
import { FaPen, FaTrash } from "react-icons/fa";
import Confirm from "../../../components/ui/confirm";
import { history } from "../../../store";

export const getColumns = (users: IMagazineResponse[], onDelete: (magazineId: string) => void) => {
    return [
        {
            title: '#',
            dataIndex: 'order',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Título',
            dataIndex: 'title',
            sorter: (a, b) => sorter(a.title, b.title),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('title', 'Título'),
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.description, b.description),
            showSorterTooltip: false,
            ...getFilterSearchProps('description', 'Descrição'),
        },
        {
            width: 170,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaPen title='Editar' onClick={() => history.push(`/admin/magazine/${register.magazineId}`)} />
                        <FaTrash title='Excluir' onClick={() => Confirm('Excluir Revista', 'Deseja realmente excluir a revista?', () => onDelete(register.magazineId))} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<IMagazineResponse>>;
}