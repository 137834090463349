import { CSSProperties } from 'react';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  EXPIRES_AT_KEY,
  ISSUED_DATETIME_KEY,
  EMAIL_KEY,
  NOME_KEY,
  CURRENT_TENANT_KEY,
  USER_ID_KEY,
  ROLE_NAME as ROLE_NAME_KEY,
  AVATAR_NAME,
  ROLE_ADMINISTRADOR,
  PHONE,
  CITY,
  UF,
  ZIPCODE,
  FULLNAME,
} from '../store/auth/Auth.constants';
import AppStorage from '../utils/AppStorage';

export class AuthUtils {
  static getLoggedUser(): ILoggedUser {
    return {
      accessToken: AppStorage.GetItem(ACCESS_TOKEN_KEY) || undefined,
      refreshToken: AppStorage.GetItem(REFRESH_TOKEN_KEY) || undefined,
      expiresIn: Number(AppStorage.GetItem(EXPIRES_AT_KEY)) || undefined,
      issuedDatetime: AppStorage.GetItem(ISSUED_DATETIME_KEY) || undefined,
      email: AppStorage.GetItem(EMAIL_KEY) || undefined,
      firstName: AppStorage.GetItem(NOME_KEY) || undefined,
      currentTenant: Number(AppStorage.GetItem(CURRENT_TENANT_KEY)) || undefined,
      userId: AppStorage.GetItem(USER_ID_KEY) || undefined,
      roleName: AppStorage.GetItem(ROLE_NAME_KEY) || undefined,
      avatarFileName: AppStorage.GetItem(AVATAR_NAME) || undefined,
      phone: AppStorage.GetItem(PHONE) || undefined,
      city: AppStorage.GetItem(CITY) || undefined,
      uf: AppStorage.GetItem(UF) || undefined,
      zipCode: AppStorage.GetItem(ZIPCODE) || undefined,
      fullName: AppStorage.GetItem(FULLNAME) || undefined,
    };
  }

  static setLoggedUser(user: ILoggedUser) {
    AppStorage.SetItem(ACCESS_TOKEN_KEY, user.accessToken || '');
    AppStorage.SetItem(REFRESH_TOKEN_KEY, user.refreshToken || '');
    AppStorage.SetItem(EXPIRES_AT_KEY, user.expiresIn?.toString() || '');
    AppStorage.SetItem(ISSUED_DATETIME_KEY, new Date().toISOString());
    AppStorage.SetItem(EMAIL_KEY, user.email || '');
    AppStorage.SetItem(NOME_KEY, user.firstName || '');
    AppStorage.SetItem(CURRENT_TENANT_KEY, user.currentTenant?.toString() || '');
    AppStorage.SetItem(USER_ID_KEY, user.userId?.toString() || '');
    AppStorage.SetItem(ROLE_NAME_KEY, user.roleName?.toString() || '');
    AppStorage.SetItem(AVATAR_NAME, user.avatarFileName?.toString() || '');
    AppStorage.SetItem(PHONE, user.phone?.toString() || '');
    AppStorage.SetItem(CITY, user.city?.toString() || '');
    AppStorage.SetItem(UF, user.uf?.toString() || '');
    AppStorage.SetItem(ZIPCODE, user.zipCode?.toString() || '');
    AppStorage.SetItem(FULLNAME, user.fullName?.toString() || '');
  }

  static logout() {
    AuthUtils.setLoggedUser({});
  }
}

export var isAccessAdmin = () => {
  return AuthUtils.getLoggedUser().roleName == ROLE_ADMINISTRADOR;
}

export var getAccessToken = () => {
  return AuthUtils.getLoggedUser().accessToken;
}

export var setVisibleAccessAdmin = isAccessAdmin() ? {} : { display: 'none' } as CSSProperties;

export interface ILoggedUser {
  accessToken?: string;
  refreshToken?: string;
  issuedDatetime?: string;
  email?: string;
  firstName?: string;
  fullName?: string;
  expiresIn?: number;
  currentTenant?: number;
  userId?: string;
  roleName?: string;
  avatarFileName?: string;
  phone?: string;
  city?: string;
  uf?: string;
  zipCode?: string;
}
