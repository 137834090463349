import { combineReducers } from 'redux';
import auth from './auth/Auth.reducer';
import user from './user/User.reducer';
import magazine from './magazine/Magazine.reducer';

export const rootReducer = combineReducers({
  auth,
  user,
  magazine
});
