import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { UserApi } from '../../data/User.api';
import { EntrarContatoAction, SendNotificaionAction, UserActions, UserUpdateAction, UserUpdatePasswordAction } from './User.actions';
import { AuthActions } from '../auth/Auth.actions';

export function* listUsers() {
  try {
    const { data } = yield call(UserApi.listUsers);
    yield put(UserActions.listUsersSuccess(data.data));
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.defaultFailure(error));
  }
}

export function* updateUser({ payload }: UserUpdateAction) {
  try {
    const { data } = yield call(UserApi.updateUser, payload);
    yield put(UserActions.updateUserSuccess(data.data));
    yield put(AuthActions.getUserInfoSuccess(data.data));
    toast.success('Usuário atualizado com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.defaultFailure(error));
  }
}

export function* updatePassword({ payload }: UserUpdatePasswordAction) {
  try {
    yield call(UserApi.updatePassword, payload);
    yield put(UserActions.updatePasswordSuccess());
    toast.success('Senha atualizada com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.defaultFailure(error));
  }
}

export function* entrarContato({ payload }: EntrarContatoAction) {
  try {
    yield call(UserApi.entrarContato, payload);
    yield put(UserActions.entrarContatoSuccess());
    toast.success('Mensagem enviada com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.defaultFailure(error));
  }
}

export function* sendNotification({ payload }: SendNotificaionAction) {
  try {
    const { data } = yield call(UserApi.sendNotification, payload);
    yield put(UserActions.sendNotificationSuccess());
    console.log('Envio Notificações', data);
    toast.success('Notificação enviada com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.defaultFailure(error));
  }
}
