import React from "react";

const PoliticaPrivacidade = () => {

    return (
        <div style={{ margin: 50 }}>
            <h2>Política de Privacidade</h2>
            <p>
                A Vmx do Brasil Adm. de Cartões e Benefícios Ltda desenvolveu o aplicativo Revista Moda Atual como
                um aplicativo gratuito. Este SERVIÇO é fornecido pela
                Vmx do Brasil Adm. de Cartões e Benefícios Ltda sem custos e destina-se ao uso conforme
                está.
            </p>
            <p>
                Esta página é utilizada para informar os visitantes sobre nossas
                políticas referentes à coleta, uso e divulgação de Informações Pessoais
                caso alguém decida utilizar nosso Serviço.
            </p>
            <p>
                Ao optar por utilizar nosso Serviço, você concorda com
                a coleta e uso de informações relacionadas a esta
                política. As Informações Pessoais que coletamos são
                utilizadas para fornecer e aprimorar o Serviço. Não utilizaremos nem compartilharemos suas informações com
                ninguém, exceto conforme descrito nesta Política de Privacidade.
            </p>
            <p>
                Os termos utilizados nesta Política de Privacidade têm os mesmos significados
                que em nossos Termos e Condições, acessíveis em
                Revista Moda Atual, a menos que definido de outra forma nesta Política de Privacidade.
            </p>
            <p><strong>Coleta e Uso de Informações</strong></p>
            <p>
                Para uma melhor experiência ao utilizar nosso Serviço, poderemos
                solicitar que você nos forneça certas informações pessoais,
                incluindo, mas não se limitando a Nome, Endereços, Números de telefone, Número de identificação pessoal, Imagens. As informações
                solicitadas serão retidas por nós e utilizadas conforme descrito nesta política de privacidade.
            </p>

            <div>
                <p>
                    O aplicativo utiliza serviços de terceiros que podem coletar
                    informações usadas para identificá-lo.
                </p>
                <p>
                    Link para a política de privacidade dos prestadores de serviços de terceiros utilizados
                    pelo aplicativo
                </p>
                <ul>
                    <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Serviços do Google Play</a></li>
                    <li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics para Firebase</a></li>
                    <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li></ul>
            </div>
            <p><strong>Dados de Registro</strong></p>
            <p>
                Gostaríamos de informar que sempre que você
                utiliza nosso Serviço e ocorre um erro no aplicativo,
                coletamos dados e informações (por meio de produtos de terceiros)
                em seu telefone, chamados Dados de Registro. Esses Dados de Registro podem
                incluir informações como o endereço de Protocolo de Internet (“IP”) do seu dispositivo, nome do dispositivo, versão do sistema operacional, a
                configuração do aplicativo ao utilizar nosso Serviço,
                a hora e a data de uso do Serviço, e outras
                estatísticas.
            </p>
            <p><strong>Cookies</strong></p>
            <p>
                Cookies são arquivos com uma pequena quantidade de dados que são
                comumente usados como identificadores exclusivos anônimos. Eles são enviados
                para o seu navegador pelos sites que você visita e são
                armazenados na memória interna do seu dispositivo.
            </p>
            <p>
                Este Serviço não utiliza explicitamente esses “cookies”. No entanto,
                o aplicativo pode usar códigos e bibliotecas de terceiros que utilizam
                “cookies” para coletar informações e melhorar seus serviços.
                Você tem a opção de aceitar ou recusar esses cookies
                e saber quando um cookie está sendo enviado para o seu dispositivo. Se você
                optar por recusar nossos cookies, pode ser que você não consiga usar algumas
                partes deste Serviço.
            </p>
            <p><strong>Prestadores de Serviços</strong></p>
            <p>
                Podemos contratar empresas e
                indivíduos de terceiros pelos seguintes motivos:
            </p>
            <ul>
                <li>Facilitar nosso Serviço;</li>
                <li>Prover o Serviço em nosso nome;</li>
                <li>Realizar serviços relacionados ao Serviço; ou</li>
                <li>Auxiliar-nos na análise de como nosso Serviço é utilizado.</li>
            </ul>
            <p>
                Queremos informar os usuários deste Serviço
                que esses terceiros têm acesso às suas Informações Pessoais.
                A razão é realizar as tarefas atribuídas a
                eles em nosso nome. No entanto, eles têm a obrigação de não
                divulgar ou utilizar as informações para qualquer outro fim.
            </p>
            <p><strong>Segurança</strong></p>
            <p>
                Valorizamos a sua confiança ao nos fornecer suas
                Informações Pessoais, e estamos empenhados em utilizar meios
                comercialmente aceitáveis para protegê-las. No entanto, lembre-se de que nenhum método
                de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos
                garantir sua segurança absoluta.
            </p>
            <p><strong>Links para Outros Sites</strong></p> <p>
                Este Serviço pode conter links para outros sites. Se você clicar em
                um link de terceiros, será direcionado para esse site. Observe
                que esses sites externos não são operados por nós.
                Portanto, recomendamos enfaticamente que você revise a
                Política de Privacidade desses sites. Não temos
                controle sobre e não assumimos responsabilidade pelo conteúdo,
                políticas de privacidade ou práticas de qualquer site ou
                serviço de terceiros.
            </p> <p><strong>Privacidade das Crianças</strong></p> <div><p>
                Estes Serviços não são direcionados a pessoas com menos de 13 anos.
                Não coletamos intencionalmente informações pessoais
                identificáveis de crianças com menos de 13 anos. No caso
                de descobrirmos que uma criança com menos de 13 anos forneceu
                informações pessoais, excluímos imediatamente essas informações de nossos servidores. Se você é pai ou responsável
                e tem conhecimento de que seu filho forneceu informações pessoais a nós,
                entre em contato conosco para que possamos tomar as medidas necessárias.
            </p></div><p><strong>Alterações a Esta Política de Privacidade</strong></p> <p>
                Podemos atualizar nossa Política de Privacidade de
                tempos em tempos. Portanto, é aconselhável revisar esta página
                periodicamente para verificar alterações. Iremos
                notificá-lo de quaisquer alterações publicando a nova Política de Privacidade nesta página.
            </p> <p>Esta política é efetiva a partir de 28/12/2028</p> <p><strong>Entre em Contato Conosco</strong></p> <p>
                Se você tiver alguma dúvida ou sugestão sobre nossa
                Política de Privacidade, não hesite em nos contatar pelo e-mail vmxdobrasil@gmail.com.
            </p>
            <p>Esta página de política de privacidade foi criada em <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>e modificada/gerada por <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>

        </div>
    );
}


export default PoliticaPrivacidade;