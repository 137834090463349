import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ILoginRequest } from "./interfaces/auth/ILoginRequest";
import { ILoginResponse } from "./interfaces/auth/ILoginResponse";
import { IUserResponse } from "./interfaces/user/IUserResponse";
import { ICreateUser } from "./interfaces/auth/ICreateUser";
import { IResetPassword } from "./interfaces/auth/IResetPassword";

export class AuthApi {
    static async signIn(body: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> {
        const axios = await Http.axios();
        return axios.post('/auth/login', body);
    }

    static async externalAuth(id: string): Promise<AxiosResponse<ILoginResponse>> {
        const axios = await Http.axios();
        return axios.get(`/ExternalAuth/GetAuthValuesByKey/${id}`);
    }

    static async getUserInfo(): Promise<AxiosResponse<IUserResponse>> {
        const axios = await Http.axios();
        return axios.get('/auth/getUserInfo');
    }

    static async createUser(request: ICreateUser): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post('/auth/register', request);
    }

    static async recoverPassword(email: string): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post(`/auth/recoverPassword?email=${email}`);
    }

    static async resetPassword(request: IResetPassword): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post(`/auth/resetPassword`, request);
    }

    static async deleteAccount() {
        const axios = await Http.axios();
        return axios.delete(`/auth/deleteAccount`);
    }
}