import React, { FC } from 'react';
import { Switch, Route } from 'react-router';
import Main from '../main';
import ShellHost from '../../components/shellHost';
import Users from '../adm/users';
import AdmMagazines from '../adm/magazines';
import AdmMagazine from '../adm/magazine';
import ResetPassword from '../resetPassword';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from '../privacy-policy';
import Contato from '../contato';
import PoliticaPrivacidade from '../politica-privacidade';
import TermosUso from '../termos-uso';
import ExternalAuth from '../auth';
import Notification from '../adm/notification';

declare global {
    interface Window {
        fbq(event: string, eventName: string, data: any): void;
    }
}

const App: FC = () => {
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {
                <>
                    <ShellHost>
                        <Switch>
                            <Route path='/' exact component={Main} />
                            <Route path='/externalauth/:id' exact component={ExternalAuth} />
                            <Route path='/contato' exact component={Contato} />
                            <Route path='/privacy-policy' exact component={PrivacyPolicy} />
                            <Route path='/politica-privacidade' exact component={PoliticaPrivacidade} />
                            <Route path='/termos-uso' exact component={TermosUso} />
                            <Route path='/reset-password' exact component={ResetPassword} />
                            <Route path='/admin/users' exact component={Users} />
                            <Route path='/admin/magazines' exact component={AdmMagazines} />
                            <Route path='/admin/magazine/:id' exact component={AdmMagazine} />
                            <Route path='/admin/notifications' exact component={Notification} />
                            <Route path='/:id' exact component={Main} />
                        </Switch>
                    </ShellHost>
                </>
            }
        </>
    );
};

export default App;
