import React, { FC } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { UserActions } from "../../../store/user/User.actions";
import styles from './Notification.module.scss';
import Form from '../../../components/ui/form';
import FieldForm from "../../../components/ui/fieldForm";
import TextArea from "../../../components/ui/textArea";
import { Button, Divider } from "antd";
import schema from './Notification.schema';
import { INotification } from "../../../data/interfaces/user/INotification";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { toast } from "react-toastify";

const NotificationForm: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const onSubmit = (values: INotification) => {
        if (values) {
            if (values.email == undefined || values.email == '') {
                if (confirm('Deseja enviar a notificação para todos os usuários?')) {
                    dispatch(UserActions.sendNotification(values));
                }
            }
            else {
                dispatch(UserActions.sendNotification(values));
            }
        }
    };

    return (
        <>
            <PageContent title='Notificação' className={styles['container']}>
                <Form onSubmit={onSubmit} schema={schema}>
                    <FieldForm label='Título' name='title' className={styles['inputForm']} ></FieldForm>
                    <TextArea label='Mensagem' name='message' className={styles['inputForm']} ></TextArea>
                    <Divider />
                    <FieldForm label='Email para Teste' name='email' className={styles['inputForm']} ></FieldForm>
                    <Button key='submit' type='primary' loading={props.isLoading} htmlType='submit'>{'Enivar Notificação'}</Button>
                </Form>
                <Divider />
                <Picker
                    data={data}
                    onEmojiSelect={(e: any) => {
                        const emoji = e.native; // The selected emoji
                        navigator.clipboard.writeText(emoji).then(() => {
                            toast.success('Emoji copiado!');
                        }).catch((err) => {
                            console.error('Failed to copy emoji: ', err);
                        });
                    }}
                />
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(NotificationForm);