import React, { FC, useEffect, useState } from 'react';
import styles from './Register.module.scss';
import Form from '../../components/ui/form';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import FieldForm from '../../components/ui/fieldForm';
import logo from '../../static/images/logo-login.jpg'
import { Button, Modal } from 'antd';
import { IGlobalReducerState } from '../../store/base/interface/IGlobalReducerState';
import { AuthActions } from '../../store/auth/Auth.actions';
import { ICreateUser } from '../../data/interfaces/auth/ICreateUser';
import FieldMask from '../../components/ui/fieldMask';
import schemaRegister from './Register.schema';
import { FaApple, FaGoogle } from 'react-icons/fa';

const Register: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isCreating, setIsCreating] = useState(false);
    const [defaultValuesRegister, setDefaultValuesRegister] = useState<ICreateUser>({} as ICreateUser);
    const [nameUser, setNameUser] = useState<string>();

    useEffect(() => {
        if (!props.isLoading && isCreating) {
            Modal.success({
                content: <div style={{ top: -19, position: 'relative' }}>
                    <h3>Cadastro realizado com sucesso!</h3>
                    <p>Olá <b>{nameUser}</b>, agora você pode realizar o login em nosso site e aproveitar todo o conteúdo!</p>
                </div>,
            });

            setIsCreating(false);
        }
    }, [props.isLoading, isCreating])

    const submitRegister = (data: ICreateUser) => {
        if (data) {
            dispatch(AuthActions.createUser(data));
            setIsCreating(true);
            setNameUser(data.firstName);
            setDefaultValuesRegister({} as ICreateUser);
        }
    };

    return (
        <>
            <div>
                <div className={styles['containerWrapper']}>
                    <div className={styles['registerWrapper']}>
                        <img src={logo}></img>
                        <div className={styles['contentWrapper']}>
                            <div className={styles['text']}>
                                <h2>Cadastre-se</h2>
                            </div>
                            <Form onSubmit={submitRegister} schema={schemaRegister} resetForm={true} initialValues={defaultValuesRegister}>
                                <div className={styles['groupField']}>
                                    <FieldForm label='Primeiro Nome' name='firstName' className='inputGroup' tagRequired={true} ></FieldForm>
                                    <FieldForm label='Sobrenome' name='lastName' className='inputGroup' tagRequired={true}></FieldForm>
                                </div>
                                <FieldMask mask='(00) 00000-0000' label='Telefone' tagRequired={true} name='phone' className='inputLoginMask'></FieldMask>
                                <FieldForm label='Email' name='email' className='inputLogin' tagRequired={true}></FieldForm>
                                <div className={styles['groupField']}>
                                    <FieldForm label='Senha' name='password' key='password' type='password' className='inputGroup' tagRequired={true}></FieldForm>
                                    <FieldForm label='Confirmar Senha' name='confirmPassword' type='password' className='inputGroup' tagRequired={true}></FieldForm>
                                </div>
                                <div style={{ fontWeight: 100, fontSize: 11, color: '#cdcdcd', width: 300 }}>
                                    Ao criar a conta, você está ciente da nossa <a href='/politica-privacidade' target='_blank'>Política de Privacidade</a> e
                                    de acordo com os <a href='/termos-uso' target='_blank'>Termos de Uso</a>.
                                </div>
                                <Button loading={props.isLoading} htmlType='submit'>Cadastrar</Button>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        type="primary"
                                        style={{ cursor: 'pointer', width: 135, fontSize: 11 }}
                                        icon={<FaGoogle size={12} style={{ position: 'relative', top: 2 }} />}
                                        onClick={() => window.document.location = `${process.env.REACT_APP_BASE_API_URL}/externalauth/google`}
                                    >
                                        <label style={{ position: 'relative', top: 0, cursor: 'pointer' }}>Entrar com Google</label>
                                    </Button>
                                    <Button
                                        type="default"
                                        style={{ cursor: 'pointer', width: 135, fontSize: 11, marginLeft: 10 }}
                                        icon={<FaApple size={18} style={{ position: 'relative', top: 2 }} />}
                                        onClick={() => window.document.location = `https://appleid.apple.com/auth/authorize?client_id=br.com.RevistaModaAtualWeb&redirect_uri=https://revistamodaatual-api.azurewebsites.net/api/externalauth/AppleHandler&response_type=code%20id_token&scope=name%20email&response_mode=form_post`}
                                    >
                                        <label style={{ position: 'relative', top: -3, cursor: 'pointer' }}>Entrar com Apple</label>
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapState = (state: IGlobalReducerState) => ({
    ...state.auth,
});


const connector = connect(
    mapState,
);

type Props = ConnectedProps<typeof connector>;

export default connector(Register);