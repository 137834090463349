import React, { FC, useEffect, useState } from 'react';
import styles from './TopBar.module.scss';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import logo from '../../../static/images/logo-top.jpg'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { IGlobalReducerState } from '../../../store/base/interface/IGlobalReducerState';
import { AuthActions } from '../../../store/auth/Auth.actions';
import { Button, Modal, Skeleton } from 'antd';
import { isAuthenticatedSelector } from '../../../store/auth/Auth.selector';
import Login from '../../../pages/login';
import { history } from '../../../store';
import Register from '../../../pages/register';

export interface ITopBar {
    onCollapseClick: () => void;
    isAuthenticated: boolean;
}

const TopBar: FC<Props> = (props) => {
    const dispatch = useDispatch();
    var isCollapsed = localStorage.getItem('menuCollapsed') === "true";
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);

    useEffect(() => {
        if (props.isAuthenticated && !props.user) {
            dispatch(AuthActions.getUserInfo());
        }
    }, [props.user]);

    useEffect(() => {
        setShowModalLogin(props.isVisibleLoginModal);
    }, [props.isVisibleLoginModal])

    const handleShowLoginModal = (isVisible: boolean) => {
        dispatch(AuthActions.setVisibilityLoginModal(isVisible));
    }

    var avatarImageUrl;
    var avatarFileName = props.user?.avatarFileName;

    if (avatarFileName && avatarFileName.length > 0)
        avatarImageUrl = `${process.env.REACT_APP_BASE_URL_STORAGE}${avatarFileName}`;

    return (
        <>
            <div className={styles['container']}>
                <div className={styles['contentLogo']} style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
                    <img src={logo} />
                </div>
                <div style={{ display: props.isAuthenticated ? 'block' : 'none' }} onClick={props.onCollapseClick} className={styles['contentCollapse']}>
                    {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </div>
                {props.isAuthenticated ?
                    <div className={styles['nomeUsuario']}>
                        {!props.user ?
                            <>
                                <Skeleton.Input style={{ width: 200 }} active={true} size={'small'} />
                            </> :
                            <>
                                <label>Olá, {props.user?.firstName}</label>
                                <img src={avatarImageUrl} />
                            </>
                        }
                    </div>
                    :
                    <div className={styles['botoesLogin']}>
                        <Button style={{ marginRight: 10 }} onClick={() => setShowModalRegister(true)}>Cadastro</Button>
                        <Button type='primary' onClick={() => handleShowLoginModal(true)}>Login</Button>
                    </div>
                }
            </div>
            {showModalLogin
                ?
                <Modal open={showModalLogin} width={100} footer={null} closable={true} closeIcon={<></>} onCancel={() => handleShowLoginModal(false)} className={styles['modalLogin']}>
                    <Login />
                </Modal>
                : null}

            {showModalRegister
                ?
                <Modal open={showModalRegister} width={100} footer={null} closable={true} closeIcon={<></>} onCancel={() => setShowModalRegister(false)} className={styles['modalLogin']}>
                    <Register />
                </Modal>
                : null}
        </>
    )
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.auth,
    isAuthenticated: isAuthenticatedSelector(state),
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ITopBar;

export default connector(TopBar);