import { IOptionData } from "../components/ui/selectForm";

export const simNaoOptions = [
    {
        text: 'NÃO',
        value: 0
    },
    {
        text: 'SIM',
        value: 1
    }
] as IOptionData[];