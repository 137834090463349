import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { AuthApi } from '../../data/Auth.api';
import { ILoginResponse } from '../../data/interfaces/auth/ILoginResponse';
import { AuthUtils, ILoggedUser } from '../../utils/AuthUtils';
import { AuthActions, CreateUserAction, ExternalAuthAction, RecoverPasswordAction, ResetPasswordAction, UserLoginAction, UserLoginSuccessAction } from "./Auth.actions";
import { MagazineActions } from '../magazine/Magazine.actions';
import { history } from '../../store';
import locations from '../../utils/estados-cidades.json';

export function* signIn({ payload }: UserLoginAction) {
  try {
    const { data } = yield call(AuthApi.signIn, payload);

    const loginResponse =
      {
        tokenResponse: JSON.parse(data.data),
        user: data.info
      } as ILoginResponse;

    yield put(MagazineActions.clearCurrentMagazine());
    yield put(AuthActions.userLoginSuccess(loginResponse));

    var user = AuthUtils.getLoggedUser();

    window.fbq('track', 'Login',
      {
        userEmail: user.email,
        userTelefone: user.phone,
        userNome: user.fullName,
        userCidade: user.city,
        userEstado: user.uf,
        userCEP: user.zipCode
      });

  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.userLoginFailure(error));
  }
}

export function* createUser({ payload }: CreateUserAction) {
  try {
    yield call(AuthApi.createUser, payload);
    yield put(AuthActions.createUserSuccess());
    toast.success('Usuário cadastrado com sucesso!');

    window.fbq('track', 'Criar Conta', {
      userEmail: payload.email,
      userTelefone: payload.phone,
      userNome: payload.firstName + " " + payload.lastName,
    });
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.defaultFailure(error));
  }
}

export function* loginSuccess({ payload }: UserLoginSuccessAction) {

  var cityAux = payload.user.cityId ? locations.cities.find(x => x?.id == payload.user.cityId) : undefined;

  AuthUtils.setLoggedUser({
    accessToken: payload.tokenResponse.access_token,
    refreshToken: payload.tokenResponse.refresh_token,
    expiresIn: payload.tokenResponse.expires_in,
    firstName: payload.user.firstName,
    roleName: payload.user.roleName,
    email: payload.user.email,
    avatarFileName: payload.user.avatarFileName,
    userId: payload.user.id,
    phone: payload.user.phone,
    city: cityAux?.name,
    uf: cityAux ? locations.states.find(x => x?.id == cityAux?.state_id)?.name : undefined,
    zipCode: payload.user.zipCode,
    fullName: payload.user.fullName
  });

  yield put(AuthActions.getUserInfoSuccess(payload.user));
  window.location.href = '/';
}

export function* getUserInfo() {
  try {
    const { data } = yield call(AuthApi.getUserInfo);
    yield put(AuthActions.getUserInfoSuccess(data.data));
    yield put(AuthActions.getInfoSuccess(data.info));
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.getUserInfoFailure(error));
  }
}

export function* recoverPassword({ payload }: RecoverPasswordAction) {
  try {
    yield call(AuthApi.recoverPassword, payload);
    yield put(AuthActions.recoverPasswordSuccess());
    toast.success('Email enviado com instruções para recuperar senha!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.defaultFailure(error));
  }
}

export function* resetPassword({ payload }: ResetPasswordAction) {
  try {
    yield call(AuthApi.resetPassword, payload);
    yield put(AuthActions.resetPasswordSuccess());
    toast.success('Senha alterada com sucesso!');
    history.push('/');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.defaultFailure(error));
  }
}

export function* deleteAccount() {
  try {
    yield call(AuthApi.deleteAccount);
    yield put(AuthActions.deleteAccountSuccess());
    yield put(AuthActions.userLoginFailure(''));
    toast.warning('Conta deletada com sucesso');

    var user = AuthUtils.getLoggedUser();

    window.fbq('track', 'Remover Conta',
      {
        userEmail: user.email,
        userTelefone: user.phone,
        userNome: user.fullName,
        userCidade: user.city,
        userEstado: user.uf,
        userCEP: user.zipCode
      });

    AuthUtils.setLoggedUser({} as ILoggedUser);
    history.push('/');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.defaultFailure(error));
  }
}

export function* externalAuth({ payload }: ExternalAuthAction) {
  try {
    const { data } = yield call(AuthApi.externalAuth, payload);

    const loginResponse =
      {
        tokenResponse: JSON.parse(data.data),
        user: data.info
      } as ILoginResponse;

    yield put(MagazineActions.clearCurrentMagazine());
    yield put(AuthActions.userLoginSuccess(loginResponse));

    var user = AuthUtils.getLoggedUser();

    window.fbq('track', 'Login',
      {
        userEmail: user.email,
        userTelefone: user.phone,
        userNome: user.fullName,
        userCidade: user.city,
        userEstado: user.uf,
        userCEP: user.zipCode
      });

  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.userLoginFailure(error));
  }
}
