import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../store/auth/Auth.actions";

export interface IMain {
    match: any;
}

const ExternalAuth: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AuthActions.externalAuth(props.match.params.id));
    }, []);
    
    return <></>;
}

type Props = IMain;

export default ExternalAuth;