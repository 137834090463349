import { IRolesResponse } from "../../data/interfaces/user/IRoles";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { IUserState } from "./interfaces/IUserState";
import { UserActionKeys, UserActionUnion } from "./User.actions";

const initialState: IUserState = {
  users: [] as IUserResponse[],
  roles: [] as IRolesResponse[],
  isLoading: false,
  error: undefined,
  message: undefined,
}

const userReducer = (state = initialState, action: UserActionUnion): IUserState => {

  var users: IUserResponse[];

  switch (action.type) {
    case UserActionKeys.LIST_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionKeys.LIST_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };

    case UserActionKeys.USER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionKeys.USER_UPDATE_SUCCESS:
      users = [...state.users];
      var oldUserIndex = users.findIndex(x => x.id == action.payload.id);
      users[oldUserIndex] = action.payload;

      return {
        ...state,
        isLoading: false,
        users: users,
      };

    case UserActionKeys.USER_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionKeys.USER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case UserActionKeys.ENTRAR_CONTATO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionKeys.ENTRAR_CONTATO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserActionKeys.SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UserActionKeys.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
