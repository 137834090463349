import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./Magazines.helper";
import styles from './Magazines.module.scss';
import { MagazineActions } from "../../../store/magazine/Magazine.actions";
import Field from "../../../components/ui/field";
import { IMagazineResponse } from "../../../data/interfaces/magazine/IMagazine";

const AdmMagazines: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [nameNewMagazine, setNameNewMagazine] = useState<string>();

    useEffect(() => {
        dispatch(MagazineActions.listMagazines());
    }, []);

    const handleNewMagazine = () => {
        if (nameNewMagazine)
            dispatch(MagazineActions.createMagazine(nameNewMagazine));
    }

    const handleDelete = (magazineId: string) => {
        dispatch(MagazineActions.deleteMagazine(magazineId));
    }

    return (
        <>
            <PageContent title='Revistas' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Field placeholder="Nome da Nova Revista" styleInput={{ width: 200, height: 18 }} onInput={(e) => setNameNewMagazine(e)}></Field>
                            <Button type='primary' style={{ marginTop: 5, marginLeft: 5 }} onClick={handleNewMagazine}>Cadastrar Nova Revista</Button>
                        </div>
                        <Table columns={getColumns(props.magazines, handleDelete)} dataSource={props.magazines} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.magazine,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(AdmMagazines);