import { IEntrarContato } from "../../data/interfaces/user/IEntrarContato";
import { INotification } from "../../data/interfaces/user/INotification";
import { IRolesResponse } from "../../data/interfaces/user/IRoles";
import { IUpdatePasswordRequest } from "../../data/interfaces/user/IUpdatePassword";
import { IUserData, IUserRequest } from "../../data/interfaces/user/IUserRequest";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum UserActionKeys {
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',

  USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',

  USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST',
  USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS',

  ENTRAR_CONTATO_REQUEST = 'ENTRAR_CONTATO_REQUEST',
  ENTRAR_CONTATO_SUCCESS = 'ENTRAR_CONTATO_SUCCESS',

  SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST',
  SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const UserActions = {
  listUsers: (): ListUsersAction =>
    createAction(UserActionKeys.LIST_USERS_REQUEST),
  listUsersSuccess: (response: IUserResponse[]): ListUsersSuccessAction =>
    createAction(UserActionKeys.LIST_USERS_SUCCESS, response),

  updateUser: (request: IUserData): UserUpdateAction =>
    createAction(UserActionKeys.USER_UPDATE_REQUEST, request),
  updateUserSuccess: (response: IUserResponse): UserUpdateSuccessAction =>
    createAction(UserActionKeys.USER_UPDATE_SUCCESS, response),

  updatePassword: (request: IUpdatePasswordRequest): UserUpdatePasswordAction =>
    createAction(UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, request),
  updatePasswordSuccess: (): UserUpdatePasswordSuccessAction =>
    createAction(UserActionKeys.USER_UPDATE_PASSWORD_SUCCESS),

  entrarContato: (request: IEntrarContato): EntrarContatoAction =>
    createAction(UserActionKeys.ENTRAR_CONTATO_REQUEST, request),
  entrarContatoSuccess: (): EntrarContatoSuccessAction =>
    createAction(UserActionKeys.ENTRAR_CONTATO_SUCCESS),

  sendNotification: (request: INotification): SendNotificaionAction =>
    createAction(UserActionKeys.SEND_NOTIFICATION_REQUEST, request),
  sendNotificationSuccess: (): SendNotificaionSuccessAction =>
    createAction(UserActionKeys.SEND_NOTIFICATION_SUCCESS),

  defaultFailure: (err: string): DefaultFailureAction => createAction(UserActionKeys.DEFAULT_FAILED, err),
};

export type UserActionUnion = ActionsUnion<typeof UserActions>;

export type ListUsersAction = Action<UserActionKeys.LIST_USERS_REQUEST>;
export type ListUsersSuccessAction = Action<UserActionKeys.LIST_USERS_SUCCESS, IUserResponse[]>;

export type UserUpdateAction = Action<UserActionKeys.USER_UPDATE_REQUEST, IUserData>;
export type UserUpdateSuccessAction = Action<UserActionKeys.USER_UPDATE_SUCCESS, IUserResponse>;

export type UserUpdatePasswordAction = Action<UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, IUpdatePasswordRequest>;
export type UserUpdatePasswordSuccessAction = Action<UserActionKeys.USER_UPDATE_PASSWORD_SUCCESS>;

export type EntrarContatoAction = Action<UserActionKeys.ENTRAR_CONTATO_REQUEST, IEntrarContato>;
export type EntrarContatoSuccessAction = Action<UserActionKeys.ENTRAR_CONTATO_SUCCESS>;

export type SendNotificaionAction = Action<UserActionKeys.SEND_NOTIFICATION_REQUEST, INotification>;
export type SendNotificaionSuccessAction = Action<UserActionKeys.SEND_NOTIFICATION_SUCCESS>;

export type DefaultFailureAction = Action<UserActionKeys.DEFAULT_FAILED, string>;