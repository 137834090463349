import {  Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { UserActions } from "../../../store/user/User.actions";
import { getColumns } from "./Users.helper";
import styles from './Users.module.scss';
import { IUserResponse } from "../../../data/interfaces/user/IUserResponse";

const Users: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UserActions.listUsers());
    }, []);

    return (
        <>
            <PageContent title='Usuários' className={styles['container']}>
                {props.isLoading || props.users?.length == 0 ?
                    <Skeleton active />
                    :
                    <>
                        <Table columns={getColumns(props.users)} dataSource={props.users} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Users);