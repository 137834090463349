import React, { FC, useEffect, useState } from 'react';
import { IGlobalReducerState } from '../../../store/base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';

import styles from './UserForm.module.scss';
import Form from '../../../components/ui/form';
import FieldForm from '../../../components/ui/fieldForm';
import schema from './UserForm.schema';
import { IUserData, IUserRequest } from '../../../data/interfaces/user/IUserRequest';
import { UserActions } from '../../../store/user/User.actions';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from '../../../utils/cropImage';
import { IUserResponse } from '../../../data/interfaces/user/IUserResponse';
import FieldMask from '../../../components/ui/fieldMask';
import SelectForm, { IOptionData } from '../../../components/ui/selectForm';
import locations from '../../../utils/estados-cidades.json';
import { AuthActions } from '../../../store/auth/Auth.actions';

export interface IUserModal {
    defaultValues: IUserResponse;
    visible: boolean;
    onClose: () => void;
}

interface IStateAvatar {
    src: any,
    crop: {
        x: number,
        y: number,
        width: number,
        height: number,
        unit?: 'px' | '%';
        aspect: number
    }
}

const initialStateAvatar = {
    crop: {
        unit: '%',
        width: 80,
        x: 10,
        y: 10,
        aspect: 1
    }
} as IStateAvatar;

const UserForm: FC<Props> = ({ defaultValues, visible, onClose, isLoading }) => {
    const dispatch = useDispatch();
    const [stateOptions, setStateOptions] = useState<IOptionData[]>([]);
    const [cityOptions, setCityOptions] = useState<IOptionData[]>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const [stateAvatar, setStateAvatar] = useState<IStateAvatar>(initialStateAvatar);
    const [avatarImageUrl, setAvatarImageUrl] = useState<any>(defaultValues.avatarFileName);
    const [image, setImage] = useState<any>();
    const [values, setValues] = useState(defaultValues);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {

        setStateOptions(locations.states.map(state => ({ value: state.id, text: state.name } as IOptionData)));

        if (values.stateId) {
            var cities = locations.cities
                .filter(city => city.state_id == values.stateId)
                .map(city => ({ value: city.id, text: city.name } as IOptionData));

            setCityOptions(cities);

            setValues({
                ...values,
                stateId: values.stateId,
                cityId: values.cityId
            })
        }
    }, []);

    useEffect(() => {
        setAvatarImageUrl(defaultValues.avatarFileName ? process.env.REACT_APP_BASE_URL_STORAGE + defaultValues.avatarFileName : null);
    }, [defaultValues.avatarFileName])

    if (!isLoading && isSendingData) {
        setIsSendingData(false);
        onClose();
    }

    if (!isLoading && isDeleting) {
        setIsDeleting(false);
        onClose();
    }

    const handleChangeState = (stateId: number) => {
        if (stateId == 0) {
            setValues({
                ...values,
                stateId: undefined,
                cityId: undefined
            })
        }
        else {
            var cities = locations.cities
                .filter(city => city.state_id == stateId)
                .map(city => ({ value: city.id, text: city.name } as IOptionData));

            setCityOptions(cities);

            setValues({
                ...values,
                stateId: stateId,
                cityId: parseInt(cities[0].value.toString())
            })
        }
    }

    const handleChangeCity = (cityId?: number) => {
        setValues({
            ...values,
            cityId: cityId
        });
    }

    const onCropAvatar = async () => {
        var avatarUrl = await getCroppedImg(
            image,
            stateAvatar.crop,
            stateAvatar.src
        );

        setAvatarImageUrl(avatarUrl);
        setStateAvatar(initialStateAvatar);
    }

    const submit = async (values: IUserRequest) => {
        if (values) {
            var blob: Blob | undefined = undefined;

            if (avatarImageUrl)
                blob = await fetch(avatarImageUrl).then(r => r.blob());

            var data = {
                data: values,
                photo: blob
            } as IUserData;

            dispatch(UserActions.updateUser(data));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () =>
                    setStateAvatar({
                        ...stateAvatar,
                        src: reader.result
                    }),
                false
            )

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onImageLoaded = (image: any) => {
        setImage(image);
    }

    const onCropChange = (crop: any) => {
        setStateAvatar({
            ...stateAvatar,
            crop
        });
    }

    const handleRemoveAccount = () => {
        dispatch(AuthActions.deleteAccount());
        setIsDeleting(true);
    }

    const confirm = () => {
        Modal.confirm({
            title: <b style={{ color: '#d13c3c' }}>Deletar Conta?</b>,
            content: <><p>Essa ação é <b style={{ color: '#d13c3c' }}>irreversível!</b></p><p> Ao confirmar essa ação sua conta será excluída!</p></>,
            okText: 'Excluir Conta',
            cancelText: 'Cancelar',
            onOk: handleRemoveAccount
        });
    };

    const buttons =
        [
            <Button onClick={confirm} type='link' key='removeAccount' style={{ left: -90, color: 'red', display: !stateAvatar.src ? 'inline' : 'none' }}>Deletar Conta</Button>,
            <Button style={{ display: stateAvatar.src ? 'inline' : 'none' }} key='back-avatar' onClick={() => setStateAvatar(initialStateAvatar)}> Voltar</Button >,
            <Button style={{ display: stateAvatar.src ? 'inline' : 'none' }} key='crop-avatar' type='primary' onClick={onCropAvatar}>Aplicar Foto</Button>,
            <Button style={{ display: !stateAvatar.src ? 'inline' : 'none' }} key='back' onClick={onClose}>Cancelar</Button>,
            <Button style={{ display: !stateAvatar.src ? 'inline' : 'none' }} key='submit' type='primary' loading={isLoading} htmlType='submit' onClick={() => setIsSubmit(true)}>{'Salvar'}</Button>,
        ];

    return (
        <Form onSubmit={submit} schema={schema} initialValues={values} isSubmited={isSubmit}>
            <Modal title={'Atutalizar Meus Dados'} open={visible} footer={buttons} onCancel={onClose} width={410}>
                <div className={styles['container']}>
                    {
                        stateAvatar.src ? (
                            <ReactCrop
                                src={stateAvatar.src}
                                crop={stateAvatar.crop}
                                circularCrop={true}
                                onImageLoaded={onImageLoaded}
                                onChange={onCropChange}
                                keepSelection={true}
                            />
                        )
                            :
                            <>
                                <FieldForm type='hidden' name='id'></FieldForm>
                                <div style={{ display: 'inline-flex' }}>
                                    <div className={styles['avatarInput']} onClick={() => document.getElementById('fileAvatar')?.click()}  >
                                        <input hidden type='file' id='fileAvatar' onChange={onSelectFile} />
                                        <img src={avatarImageUrl} />
                                    </div>
                                </div>
                                <div className={styles['groupField']}>
                                    <FieldForm label='Primeiro Nome' name='firstName' className={styles['inputGroup']} tagRequired={true} ></FieldForm>
                                    <FieldForm label='Sobrenome' name='lastName' className={styles['inputGroup']} tagRequired={true}></FieldForm>
                                </div>
                                <FieldForm key='email' label='Email' name='email' tagRequired={true} className={styles['inputForm']}></FieldForm>
                                <div className={styles['groupField']}>
                                    <FieldMask mask='(00) 00000-0000' key='phone' label='Telefone' tagRequired={true} name='phone' className={styles['inputGroupMask']}></FieldMask>
                                    <FieldMask mask='000.000.000-00' label='CPF' name='cpf' className={styles['inputGroupMask']}></FieldMask>
                                </div>
                                <FieldForm label='Endereço' name='address' className={styles['inputForm']} ></FieldForm>
                                <div className={styles['groupField']}>
                                    <FieldMask mask='00000-000' key='zipCode' label='CEP' name='zipCode' className={styles['inputGroupMask']}></FieldMask>
                                    <FieldForm key='bairro' label='Bairro' name='locality' className={styles['inputGroup']}></FieldForm>
                                </div>
                                <div className={styles['groupField']}>
                                    <SelectForm key='stateId' label='Estado' name='stateId' placeholder='Escolha um Estado' options={stateOptions} styleSelect={{ width: 175, marginRight: 10 }} onSelect={handleChangeState} onClear={() => handleChangeState(0)} ></SelectForm>
                                    <SelectForm key='cityId' label='Cidade' name='cityId' options={cityOptions} placeholder={!values.stateId ? 'Escolha um Estado' : 'Escolha uma Cidade'} disabled={!values.stateId} styleSelect={{ width: 175 }} onSelect={handleChangeCity} onClear={() => handleChangeCity()}></SelectForm>
                                </div>
                            </>
                    }
                </div>
            </Modal>
        </Form>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IUserModal;

export default connector(UserForm);