import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { MagazineApi } from '../../data/Magazine.api';
import { CreateMagazineAction, DeleteMagazineAction, DeletePageMagazineAction, GetMagazineAction, MagazineActions, SendPagesAction, UpdateMagazineAction } from './Magazine.actions';
import { history } from '../../store';

export function* listMagazines() {
  try {
    const { data } = yield call(MagazineApi.listMagazines);
    yield put(MagazineActions.listMagazinesSuccess(data.data));
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* getMagazine({ payload }: GetMagazineAction) {
  try {
    const { data } = yield call(MagazineApi.getMagazine, payload);
    yield put(MagazineActions.getMagazineSuccess(data.data));
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* getFirstPageMagazine() {
  try {
    const { data } = yield call(MagazineApi.getFirstPageMagazine);
    yield put(MagazineActions.getFirstPageMagazineSuccess(data.data));
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}


export function* createMagazine({ payload }: CreateMagazineAction) {
  try {
    const { data } = yield call(MagazineApi.createMagazine, payload);
    yield put(MagazineActions.createMagazineSuccess(data.data));
    history.push(`/admin/magazine/${data.data.magazineId}`);
    toast.success('Revista cadastrada com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* updateMagazine({ payload }: UpdateMagazineAction) {
  try {
    const { data } = yield call(MagazineApi.updateMagazine, payload);
    yield put(MagazineActions.updateMagazineSuccess(data.data));
    toast.success('Revista atualizada com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* sendPages({ payload }: SendPagesAction) {
  try {
    const { data } = yield call(MagazineApi.sendPages, payload);
    yield put(MagazineActions.sendPagesSuccess(data.data));
    toast.success('Páginas enviadas com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* deleteMagazine({ payload }: DeleteMagazineAction) {
  try {
    yield call(MagazineApi.deleteMagazine, payload);
    yield put(MagazineActions.deleteMagazineSuccess(payload));
    toast.success('Revista excluída com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}

export function* deleteMagazinePage({ payload }: DeletePageMagazineAction) {
  try {
    yield call(MagazineApi.deleteMagazinePage, payload);
    yield put(MagazineActions.deletePageMagazineSuccess(payload));
    toast.success('Página excluída com sucesso!');
  } catch (e: any) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MagazineActions.defaultFailure(error));
  }
}