import { all, takeLatest } from "redux-saga/effects";
import { AuthActionKeys } from "./auth/Auth.actions";
import { createUser, deleteAccount, externalAuth, getUserInfo, loginSuccess, recoverPassword, resetPassword, signIn } from "./auth/Auth.sagas";
import { UserActionKeys } from "./user/User.actions";
import { entrarContato, listUsers, sendNotification, updatePassword, updateUser } from "./user/User.sagas";
import { MagazineActionKeys } from "./magazine/Magazine.actions";
import { createMagazine, deleteMagazine, deleteMagazinePage, getFirstPageMagazine, getMagazine, listMagazines, sendPages, updateMagazine } from "./magazine/Magazine.sagas";

export function* rootSaga() {
    yield all([
        takeLatest(AuthActionKeys.AUTH_LOGIN_REQUEST, signIn),
        takeLatest(AuthActionKeys.CREATE_USER_REQUEST, createUser),
        takeLatest(AuthActionKeys.AUTH_LOGIN_SUCCESS, loginSuccess),
        takeLatest(AuthActionKeys.GET_USER_INFO_REQUEST, getUserInfo),
        takeLatest(AuthActionKeys.RECOVER_PASSWORD_REQUEST, recoverPassword),
        takeLatest(AuthActionKeys.RESET_PASSWORD_REQUEST, resetPassword),
        takeLatest(AuthActionKeys.DELETE_ACCOUNT_REQUEST, deleteAccount),
        takeLatest(AuthActionKeys.EXTERNAL_AUTH_REQUEST, externalAuth),

        takeLatest(UserActionKeys.LIST_USERS_REQUEST, listUsers),
        takeLatest(UserActionKeys.USER_UPDATE_REQUEST, updateUser),
        takeLatest(UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, updatePassword),
        takeLatest(UserActionKeys.ENTRAR_CONTATO_REQUEST, entrarContato),
        takeLatest(UserActionKeys.SEND_NOTIFICATION_REQUEST, sendNotification),

        takeLatest(MagazineActionKeys.LIST_MAGAZINES_REQUEST, listMagazines),
        takeLatest(MagazineActionKeys.CREATE_MAGAZINE_REQUEST, createMagazine),
        takeLatest(MagazineActionKeys.UPDATE_MAGAZINE_REQUEST, updateMagazine),
        takeLatest(MagazineActionKeys.DELETE_MAGAZINE_REQUEST, deleteMagazine),
        takeLatest(MagazineActionKeys.DELETE_MAGAZINE_PAGE_REQUEST, deleteMagazinePage),
        takeLatest(MagazineActionKeys.GET_MAGAZINE_REQUEST, getMagazine),
        takeLatest(MagazineActionKeys.GET_FRIST_PAGE_MAGAZINE_REQUEST, getFirstPageMagazine),
        takeLatest(MagazineActionKeys.SEND_PAGES_REQUEST, sendPages),
    ]);
}