import React, { FC, useEffect, useState } from 'react';
import styles from './Login.module.scss';
import Form from '../../components/ui/form';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import FieldForm from '../../components/ui/fieldForm';
import schemaLogin from './Login.schema';
import logo from '../../static/images/logo-login.jpg'
import { Button, Modal } from 'antd';
import { IGlobalReducerState } from '../../store/base/interface/IGlobalReducerState';
import { ILoginRequest } from '../../data/interfaces/auth/ILoginRequest';
import { AuthActions } from '../../store/auth/Auth.actions';
import schemaRecoverPassword from './RecoverPassword.schema';
import { FaApple, FaGoogle } from "react-icons/fa";
import { relative } from 'path';

const Login: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [showRecoverPassword, setShowRecoverPassword] = useState(false);

    const submitLogin = (data: ILoginRequest) => {
        dispatch(AuthActions.userLogin(data));
    };

    const submitRecoverPassword = ({ email }: any) => {
        if (email) {
            dispatch(AuthActions.recoverPassword(email));
        }
    }

    return (
        <>
            <div>
                <div className={styles['containerWrapper']}>
                    <div className={styles['loginWrapper']}>
                        <img src={logo}></img>
                        <div className={styles['contentWrapper']} style={{ display: showRecoverPassword ? 'none' : 'block' }}>
                            <div className={styles['text']}>
                                <h2>Login</h2>
                            </div>
                            <Form onSubmit={submitLogin} schema={schemaLogin} resetForm={true}>
                                <FieldForm label='Email' name='email' className='inputLogin'></FieldForm>
                                <FieldForm label='Senha' name='password' type='password' className='inputLogin'></FieldForm>
                                <Button loading={props.isLoading} htmlType='submit'>Entrar</Button>
                            </Form>
                            <div style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setShowRecoverPassword(true)}>
                                Esqueceu a senha?
                            </div>
                            <Button
                                type="primary"
                                style={{ cursor: 'pointer' }}
                                icon={<FaGoogle size={20} style={{ position: 'relative', top: 2 }} />}
                                onClick={() => window.document.location = `${process.env.REACT_APP_BASE_API_URL}/externalauth/google`}
                            >
                                <label style={{ position: 'relative', top: -3, cursor: 'pointer' }}>Entrar com Google</label>
                            </Button>
                            <Button
                                type="default"
                                icon={<FaApple size={22} style={{ position: 'relative', top: 2 }} />}
                                onClick={() => window.document.location = `https://appleid.apple.com/auth/authorize?client_id=br.com.RevistaModaAtualWeb&redirect_uri=https://revistamodaatual-api.azurewebsites.net/api/externalauth/AppleHandler&response_type=code%20id_token&scope=name%20email&response_mode=form_post`}
                            >
                                <label style={{ position: 'relative', top: -3, cursor: 'pointer' }}>Entrar com Apple</label>
                            </Button>
                        </div>
                        <div className={styles['contentWrapper']} style={{ display: !showRecoverPassword ? 'none' : 'block' }}>
                            <div className={styles['textRecover']}>
                                <h2>Recuperar Senha</h2>
                            </div>
                            <Form onSubmit={(e) => submitRecoverPassword(e)} schema={schemaRecoverPassword} resetForm={true}>
                                <FieldForm label='Email' name='email' className='inputLogin'></FieldForm>
                                <Button loading={props.isLoading} htmlType='submit'>Recuperar Senha</Button>
                            </Form>
                            <div style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setShowRecoverPassword(false)}>
                                Voltar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapState = (state: IGlobalReducerState) => ({
    ...state.auth,
});


const connector = connect(
    mapState,
);

type Props = ConnectedProps<typeof connector>;

export default connector(Login);