import React, { FC, useEffect } from "react";
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { ConnectedProps, connect, useDispatch } from "react-redux";
import { MagazineActions } from "../../store/magazine/Magazine.actions";
import styles from './Main.module.scss';
import { isAuthenticatedSelector } from "../../store/auth/Auth.selector";
import loading from '../../static/images/loading.gif';
import { AuthActions } from "../../store/auth/Auth.actions";
import { AuthUtils } from "../../utils/AuthUtils";

export interface IMain {
    match: any;
    isAuthenticated: boolean;
}

const Main: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isAuthenticated)
            dispatch(MagazineActions.listMagazines());
    }, [props.isAuthenticated]);

    useEffect(() => {
        {
            props.isAuthenticated
                ? dispatch(MagazineActions.getMagazine(props.match.params.id))
                : dispatch(MagazineActions.getFirstPageMagazine());
        }
    }, [props.match.params.id]);

    const handleShowLoginModal = () => {
        dispatch(AuthActions.setVisibilityLoginModal(true));
    }

    useEffect(() => {
        if (props.isAuthenticated && props.currentMagazine.title) {

            var user = AuthUtils.getLoggedUser();

            window.fbq('track', 'Visualizar Revista',
                {
                    revistaId: props.currentMagazine.magazineId,
                    revistaNome: props.currentMagazine.title,
                    userEmail: user.email,
                    userTelefone: user.phone,
                    userNome: user.fullName,
                    userCidade: user.city,
                    userEstado: user.uf,
                    userCEP: user.zipCode
                });
        }
    }, [props.currentMagazine.title])

    return (
        <>
            <div style={{
                position: 'relative',
                width: '100%',
                height: 'calc(100vh - 64px)',
                background: '#505050',
                backgroundImage: `url(${loading})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                backgroundSize: 80,
                overflow: 'hidden'
            }}>
                <iframe
                    style={{ border: 'none', width: '100%', height: '100%', left: 0, top: 0 }}
                    src={`${window.location.origin}/flipbook/index.html?magazineId=${props.currentMagazine?.magazineId}&totalPages=${props.currentMagazine?.pages?.length}`}
                    seamless={true}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                ></iframe>
                <div onClick={handleShowLoginModal} className={styles['btnNextLogin']} style={{ display: props.isAuthenticated ? 'none' : 'block' }}>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAACgCAYAAAC7b7RJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAb5JREFUeNrs3cFqwkAQBmC1padee/Up+v5P0KfINdeeCsUmEKEUcyi4M7PuNyBiQOH7SbK7kwWPl8vlMGqdDgMXPDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PDw8PPxw9XzPH3t9ejkvb2/bx3l5Tf/5/uf3V7/4Fb4APrYg3rdj0yin/XxFbyGsZ8F5FPzUUwAtbnjdBNDqbt9FAC2HuvIBtB7nSwcQMckpG0DUDK9kAJHT23IBRM/tSwWQsbApE0DWqu5mANvCaIglbXoA2ev51AAqNDPSAqjSyUkJoFIbKzyAUj28BRwaQLkGZmQAJbu3UQGUbV3vBTAEfpRx/mZdnwH8aYXPD4/fg2+XwuPio+Dl8JHwavhQeCV8OLwKPgVeAZ8Gz8anwjPx6fAs/N7MLXwTw2lUeDS+FDwSXw4ehS8Jj8CXhbfGl4a3xJeHt8J3AW+B7wbeAt8NfK17772df+25LQ1f6+ivXODh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4ceoHwEGADezNUZr3+GBAAAAAElFTkSuQmCC" />
                </div>
            </div >
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.magazine,
    ...state.user,
    isAuthenticated: isAuthenticatedSelector(state),
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IMain;

export default connector(Main);
