import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IUserResponse } from "./interfaces/user/IUserResponse";
import { IUserData } from "./interfaces/user/IUserRequest";
import { IUpdatePasswordRequest } from "./interfaces/user/IUpdatePassword";
import { IEntrarContato } from "./interfaces/user/IEntrarContato";
import { INotification } from "./interfaces/user/INotification";

export class UserApi {
    static async listUsers(): Promise<AxiosResponse<IUserResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/user/list');
    }

    static async updateUser(body: IUserData): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('photo', body.photo);
        formData.append('data', JSON.stringify(body.data));

        return axios.put(`/user/update/${body.data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async updatePassword(body: IUpdatePasswordRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.put(`/user/updatepassword/${body.id}`, body);
    }

    static async entrarContato(body: IEntrarContato) {
        const axios = await Http.axios();
        return axios.post('/user/contato', body);
    }

    static async sendNotification(body: INotification) {
        const axios = await Http.axios();
        return axios.post('/user/sendNotification', body);
    }
}