import { ICreateUser } from "../../data/interfaces/auth/ICreateUser";
import { ILoginRequest } from "../../data/interfaces/auth/ILoginRequest";
import { ILoginResponse } from "../../data/interfaces/auth/ILoginResponse";
import { IResetPassword } from "../../data/interfaces/auth/IResetPassword";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum AuthActionKeys {
  AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',

  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',

  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',

  GET_INFO_SUCCESS = 'GET_INFO_SUCCESS',

  SET_VISIBILITY_LOGIN_MODAL = 'SET_VISIBILITY_LOGIN_MODAL',

  RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',

  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',

  DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS',

  EXTERNAL_AUTH_REQUEST = 'EXTERNAL_AUTH_REQUEST',
  EXTERNAL_AUTH_SUCCESS = 'EXTERNAL_AUTH_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const AuthActions = {
  userLogin: (userData: ILoginRequest): UserLoginAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_REQUEST, userData),
  userLoginSuccess: (response: ILoginResponse): UserLoginSuccessAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_SUCCESS, response),
  userLoginFailure: (err: string): UserLoginFailureAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_FAILED, err),

  createUser: (request: ICreateUser): CreateUserAction =>
    createAction(AuthActionKeys.CREATE_USER_REQUEST, request),
  createUserSuccess: (): CreateUserSuccessAction =>
    createAction(AuthActionKeys.CREATE_USER_SUCCESS),

  getUserInfo: (): GetUserInfoAction =>
    createAction(AuthActionKeys.GET_USER_INFO_REQUEST),
  getUserInfoSuccess: (response: IUserResponse): GetUserInfoSuccessAction =>
    createAction(AuthActionKeys.GET_USER_INFO_SUCCESS, response),
  getUserInfoFailure: (err: string): GetUserInfoFailureAction =>
    createAction(AuthActionKeys.GET_USER_INFO_FAILED, err),

  getInfoSuccess: (response: string[]): GetInfoSuccessAction =>
    createAction(AuthActionKeys.GET_INFO_SUCCESS, response),

  setVisibilityLoginModal: (request: boolean): SetVisibilityLoginModalAction =>
    createAction(AuthActionKeys.SET_VISIBILITY_LOGIN_MODAL, request),

  recoverPassword: (request: string): RecoverPasswordAction =>
    createAction(AuthActionKeys.RECOVER_PASSWORD_REQUEST, request),
  recoverPasswordSuccess: (): RecoverPasswordSuccessAction =>
    createAction(AuthActionKeys.RECOVER_PASSWORD_SUCCESS),

  resetPassword: (request: IResetPassword): ResetPasswordAction =>
    createAction(AuthActionKeys.RESET_PASSWORD_REQUEST, request),
  resetPasswordSuccess: (): ResetPasswordSuccessAction =>
    createAction(AuthActionKeys.RESET_PASSWORD_SUCCESS),

  deleteAccount: (): DeleteAccountAction =>
    createAction(AuthActionKeys.DELETE_ACCOUNT_REQUEST),
  deleteAccountSuccess: (): DeleteAccountSuccessAction =>
    createAction(AuthActionKeys.DELETE_ACCOUNT_SUCCESS),

  externalAuth: (id: string): ExternalAuthAction =>
    createAction(AuthActionKeys.EXTERNAL_AUTH_REQUEST, id),
  externalAuthSuccess: (response: ILoginResponse): ExternalAuthSuccessAction =>
    createAction(AuthActionKeys.EXTERNAL_AUTH_SUCCESS, response),

  defaultFailure: (err: string): DefaultFailureAction => createAction(AuthActionKeys.DEFAULT_FAILED, err),
}

export type AuthActionUnion = ActionsUnion<typeof AuthActions>;

export type UserLoginAction = Action<AuthActionKeys.AUTH_LOGIN_REQUEST, ILoginRequest>;
export type UserLoginSuccessAction = Action<AuthActionKeys.AUTH_LOGIN_SUCCESS, ILoginResponse>;
export type UserLoginFailureAction = Action<AuthActionKeys.AUTH_LOGIN_FAILED, string>;

export type CreateUserAction = Action<AuthActionKeys.CREATE_USER_REQUEST, ICreateUser>;
export type CreateUserSuccessAction = Action<AuthActionKeys.CREATE_USER_SUCCESS>;

export type GetUserInfoAction = Action<AuthActionKeys.GET_USER_INFO_REQUEST>;
export type GetUserInfoSuccessAction = Action<AuthActionKeys.GET_USER_INFO_SUCCESS, IUserResponse>;
export type GetUserInfoFailureAction = Action<AuthActionKeys.GET_USER_INFO_FAILED, string>;

export type GetInfoSuccessAction = Action<AuthActionKeys.GET_INFO_SUCCESS, string[]>;

export type SetVisibilityLoginModalAction = Action<AuthActionKeys.SET_VISIBILITY_LOGIN_MODAL, boolean>;

export type RecoverPasswordAction = Action<AuthActionKeys.RECOVER_PASSWORD_REQUEST, string>;
export type RecoverPasswordSuccessAction = Action<AuthActionKeys.RECOVER_PASSWORD_SUCCESS>;

export type ResetPasswordAction = Action<AuthActionKeys.RESET_PASSWORD_REQUEST, IResetPassword>;
export type ResetPasswordSuccessAction = Action<AuthActionKeys.RESET_PASSWORD_SUCCESS>;

export type DeleteAccountAction = Action<AuthActionKeys.DELETE_ACCOUNT_REQUEST>;
export type DeleteAccountSuccessAction = Action<AuthActionKeys.DELETE_ACCOUNT_SUCCESS>;

export type ExternalAuthAction = Action<AuthActionKeys.EXTERNAL_AUTH_REQUEST, string>;
export type ExternalAuthSuccessAction = Action<AuthActionKeys.EXTERNAL_AUTH_SUCCESS, ILoginResponse>;

export type DefaultFailureAction = Action<AuthActionKeys.DEFAULT_FAILED, string>;