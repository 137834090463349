import React, { FC, useEffect, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import styles from './LeftBar.module.scss';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { SettingOutlined, LogoutOutlined, UserOutlined, BookOutlined } from '@ant-design/icons';
import { AuthUtils, ILoggedUser, isAccessAdmin } from '../../../utils/AuthUtils';
import { IGlobalReducerState } from '../../../store/base/interface/IGlobalReducerState';
import { ConnectedProps, connect } from 'react-redux';
import { isAuthenticatedSelector } from '../../../store/auth/Auth.selector';
import UserForm from '../../../pages/user/modals'
import { IUserResponse } from '../../../data/interfaces/user/IUserResponse';
import { history } from '../../../store';
import { useDispatch } from 'react-redux';
import { MagazineActions } from '../../../store/magazine/Magazine.actions';
type MenuItem = Required<MenuProps>['items'][number];

export interface ILeftBar {
    isCollapsed: boolean;
    isAuthenticated: boolean;
}

const rootSubmenuKeys = ['/', '/admin/magazines'];

const LeftBar: FC<Props> = (props) => {
    var dispatch = useDispatch();
    var defaultOpenKeys: string[] = [];
    var defaultSelectedKeys: string[] = [];
    var pathName = window.location.pathname;

    if (pathName.split('/').length == 2) {
        defaultOpenKeys = [];
        defaultSelectedKeys = [pathName];
    }
    else {
        defaultOpenKeys = [pathName.substring(0, pathName.indexOf('/', 2))];
        defaultSelectedKeys = [`/${pathName.split('/')[1]}/${pathName.split('/')[2]}`];
    }

    const [openKeys, setOpenKeys] = useState(props.isCollapsed ? [] : defaultOpenKeys);
    const [showUserModal, setShowUserModal] = useState(false);

    const onOpenChange = (keys: any) => {
        const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onChange = (e: any) => {
        if (e.keyPath && e.keyPath.length == 1) {
            setOpenKeys([]);
        }
    }

    const onLogout = () => {

        var user = AuthUtils.getLoggedUser();

        window.fbq('track', 'Logout',
            {
                userEmail: user.email,
                userTelefone: user.phone,
                userNome: user.fullName,
                userCidade: user.city,
                userEstado: user.uf,
                userCEP: user.zipCode
            });

        AuthUtils.setLoggedUser({} as ILoggedUser);
        window.location.href = '/';
    }

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        style?: React.CSSProperties,
        children?: MenuItem[],
        className?: string,
        onClick?: () => void,
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
            style,
            className,
            onClick
        } as MenuItem;
    }

    const handleGoToMagazine = (magazineId: string) => {
        if (magazineId != props.currentMagazine.magazineId) {
            dispatch(MagazineActions.clearCurrentMagazine());
            history.push(`/${magazineId}`);
        }
    }

    var itemsRevistas = props.magazines?.filter(x => x.isActive == 1).map(magazine =>
        getItem(<>{magazine.title}</>, `/?magazineId=${magazine.magazineId}`, undefined, undefined, undefined, undefined, () => handleGoToMagazine(magazine.magazineId)),
    );

    const items: MenuItem[] = [
        getItem('Revistas', '/history', <BookOutlined />, undefined, itemsRevistas),
        getItem(<>Meus Dados</>, '/user', <UserOutlined />, undefined, undefined, undefined, () => setShowUserModal(true)),
        isAccessAdmin() ? getItem('Admin', '/admin', <SettingOutlined />, undefined, [
            getItem(<>Revistas <Link to='/admin/magazines' /></>, '/admin/magazines'),
            getItem(<>Usuários <Link to='/admin/users' /></>, '/admin/users'),
            getItem(<>Notificação <Link to='/admin/notifications' /></>, '/admin/notifications'),
        ]) : null,
        getItem(<> Sair</>, '/logout', <LogoutOutlined />, undefined, undefined, undefined, onLogout),
    ];

    return (
        <>
            {
                props.isAuthenticated
                    ?
                    <div className={`${styles['containerBar']} ${styles[props.isCollapsed ? 'containerBarCollapsed' : '']}`}>
                        <Menu
                            defaultSelectedKeys={defaultSelectedKeys}
                            mode='inline'
                            theme='dark'
                            inlineCollapsed={props.isCollapsed}
                            style={{ height: '100%' }}
                            className={styles['menu']}
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                            onClick={onChange}
                            items={items}
                        />
                    </div >
                    : null}
            {
                showUserModal ?
                    <UserForm visible={showUserModal} defaultValues={props.user ?? {} as IUserResponse} onClose={() => setShowUserModal(false)} /> :
                    null
            }
        </>
    );
};

const mapState = (state: IGlobalReducerState) => ({
    ...state.magazine,
    ...state.auth,
    isAuthenticated: isAuthenticatedSelector(state),
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ILeftBar;

export default connector(LeftBar);