import React, { FC, useState } from "react";
import Form from "../../components/ui/form";
import FieldForm from "../../components/ui/fieldForm";
import TextArea from "../../components/ui/textArea";
import { Button } from "antd";
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { ConnectedProps, connect, useDispatch } from "react-redux";
import { UserActions } from "../../store/user/User.actions";
import { IEntrarContato } from "../../data/interfaces/user/IEntrarContato";

const Contato: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);

    const submit = (values: IEntrarContato) => {

        console.log('page');
        if (values) {
            dispatch(UserActions.entrarContato(values));
        }

        setIsSubmit(false);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
            <div>
                <h3>Entre em contato com nossa equipe</h3>
                <Form onSubmit={submit} isSubmited={isSubmit}>
                    <FieldForm label="Assunto" name="subject" style={{ width: 400 }} styleInput={{ width: 400 }}></FieldForm>
                    <TextArea label="Mensagem" name="message" rows={5}></TextArea>
                </Form>
                <Button key="submit" type="primary" loading={props.isLoading} htmlType="submit" onClick={() => setIsSubmit(true)}>
                    {'Enviar Mensagem'}
                </Button>
            </div>
        </div>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Contato);